import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Image from 'gatsby-plugin-sanity-image'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Block from '../../components/block'

const BannerHome = ({ title, excerpt, image, ctaEnable, ctaLink, ctaText }) => (
  <section className="home-banner main--bg-green">
    <Container>
      <Row className="align-items-end justify-content-between">
        <Col xs={12} sm={12} md={4} lg={4}>
          <article className="home-banner--excerpt animate__animated animate__fadeInLeft">
            <h2 className="home-banner--excerpt__title margin-bottom-50">
              <Block data={title} tag="div" />
            </h2>
            <Block data={excerpt} tag="p" />
          </article>
          {ctaEnable && (
            <>
              <AnchorLink 
                href={ctaLink}
                className="btn btn-block btn-main text-uppercase margin-bottom-15 margin-top-30 d-block d-md-none"
              >
                Try One Month Free
              </AnchorLink>
              <AnchorLink
                href={ctaLink}
                className="btn btn-block btn-outline-main text-uppercase margin-top-50 animate__animated animate__fadeInUp"
              >
                {ctaText}
              </AnchorLink>
            </>
          )}
        </Col>
        <Col xs={12} sm={12} md={5} lg={5} className="d-none d-md-block">
          <figure>
            <Image
              {...image}
              className="d-block img-fluid"
              alt="Salesprout"
            />
          </figure>
        </Col>
      </Row>
    </Container>
  </section>
)

export default BannerHome
