import React from 'react'
import { Col } from 'reactstrap'
import Image from 'gatsby-plugin-sanity-image'

const Client = ({ title, logo }) => (
  <Col xs={6} sm={6} md={3} lg={3} className="animate__animated animate__fadeInUp home-clients--item">
    <Image
      {...logo}
      className="d-block m-auto img-fluid"
      alt={title}
      title={title}
    />
  </Col>
)

export default Client
