import React from "react"
import { Container, Row, Col } from "reactstrap"
import { graphql } from "gatsby"

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import { useMediaQueries } from "@react-hook/media-query"
import Slider from "react-slick"

import Block from "../components/block"
import SEO from "../components/seo"
import MainMenu from "../components/menu"
import Layout from "../components/layout/home"
import BannerMain from "../components/banner/home"
import BannerCTA from "../components/banner"
import Service from "../components/service"
import Testimonial from "../components/testimonial"
import Client from "../components/client"

import "../graphql/home"
import ContactForm from "../components/form"

const HomePage = ({ data }) => {
  const {
    sanityLayoutHome: {
      title: pageTitle,
      seo,
      tabs: {
        bannerCtaFirst,
        bannerCtaSecond,
        bannerMain,
        services: sectionService,
        testimonials: sectionTestimonial,
        clients: sectionClient,
        contact: sectionContact,
      },
    },
  } = data

  const {
    matches: { mobileSlide, tabletSlide },
  } = useMediaQueries({
    mobileSlide: "only screen and (max-width: 580px)",
    tabletSlide: "only screen and (max-width: 850px) and (min-width: 581px) ",
  })

  const sliderQuantityServices = (mobileSlide && 1) || (tabletSlide && 2) || 3
  const sliderSettingsServices = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: sliderQuantityServices,
    slidesToScroll: sliderQuantityServices,
  }

  const sliderSettingsTestimonials = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_GOGGLE_RECAPTCHA_SITE_KEY}
    >
      <Layout>
        <SEO title={pageTitle} data={seo} />
        <MainMenu />
        {bannerMain?.enable && (
          <BannerMain
            title={bannerMain?.title}
            excerpt={bannerMain?.excerpt}
            image={bannerMain?.image}
            ctaEnable={bannerMain?.ctaEnable}
            ctaLink={bannerMain?.ctaLink}
            ctaText={bannerMain?.ctaText}
          />
        )}
        {sectionService?.enable && (
          <section className="home-services">
            <Container>
              <Slider {...sliderSettingsServices}>
                {sectionService?.services.map(s => (
                  <Service
                    key={s?._key}
                    title={s?.title}
                    icon={s?.icon}
                    description={s?.description}
                  />
                ))}
              </Slider>
              {sectionService?.titleEnable && (
                <Row className="align-items-start">
                  <Col xs={12} sm={12} md={2} lg={2} className="home-title">
                    <div className="home-title--sub">
                      {sectionService?.title}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className="home-title">
                    <div className="home-title--big">
                      <Block data={sectionService?.excerpt} />
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          </section>
        )}
        {sectionTestimonial?.enable && (
          <section className="home-testimonials">
            <Container>
              <Slider {...sliderSettingsTestimonials}>
                {sectionTestimonial?.testimonials?.map(t => (
                  <Testimonial
                    key={t?._key}
                    author={t?.author}
                    image={t?.image}
                    description={t?.description}
                  />
                ))}
              </Slider>
            </Container>
          </section>
        )}
        {sectionClient?.enable && (
          <section className="home-clients">
            <Container>
              <Row className="align-items-start">
                <Col xs={12} sm={12} md={2} lg={2} className="home-title">
                  <div className="home-title--sub">{sectionClient?.title}</div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} className="home-title">
                  <div className="home-title--big">
                    <Block data={sectionClient?.excerpt} />
                  </div>
                </Col>
              </Row>
            </Container>
            <Container fluid={true}>
              <Row className="align-items-center justify-content-center margin-top-30">
                {sectionClient?.clients?.map(c => (
                  <Client key={c?._key} title={c?.title} logo={c?.logo} />
                ))}
              </Row>
            </Container>
          </section>
        )}
        {bannerCtaFirst?.enable && (
          <section className="home-banner-cta">
            <Container>
              <Row className="align-items-start justify-content-center">
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className="animate__animated"
                >
                  <BannerCTA
                    key={`cta-first`}
                    title={bannerCtaFirst?.title}
                    excerpt={bannerCtaFirst?.excerpt}
                    ctaEnable={bannerCtaFirst?.ctaEnable}
                    ctaLink={bannerCtaFirst?.ctaLink}
                    ctaText={bannerCtaFirst?.ctaText}
                    logo={bannerCtaFirst?.logo}
                    logoEnable={bannerCtaFirst?.logoEnable}
                  />
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {sectionContact?.enable && (
          <section id="contact-us" className="home-contact">
            <Container>
              <Row className="align-items-start justify-content-between">
                <Col xs={12} sm={12} md={6} lg={6} className="home-title">
                  <div className="home-title--sub margin-bottom-30">
                    {sectionContact?.title}
                  </div>
                  <div className="home-title--big margin-bottom-15">
                    <Block data={sectionContact?.excerpt} />
                  </div>
                </Col>
                {sectionContact?.formEnable && (
                  <Col xs={12} sm={12} md={6} lg={6} className="">
                    <ContactForm contact={sectionContact?.ctaText} />
                  </Col>
                )}
              </Row>
            </Container>
          </section>
        )}
        {bannerCtaSecond?.enable && (
          <section className="home-banner-cta">
            <Container>
              <Row className="align-items-start justify-content-center">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="animate__animated"
                >
                  <BannerCTA
                    key={`cta-first`}
                    title={bannerCtaSecond?.title}
                    excerpt={bannerCtaSecond?.excerpt}
                    ctaEnable={bannerCtaSecond?.ctaEnable}
                    ctaLink={bannerCtaSecond?.ctaLink}
                    ctaText={bannerCtaSecond?.ctaText}
                    logo={bannerCtaSecond?.logo}
                    logoEnable={bannerCtaSecond?.logoEnable}
                  />
                </Col>
              </Row>
            </Container>
          </section>
        )}
      </Layout>
    </GoogleReCaptchaProvider>
  )
}

export default HomePage
export const query = graphql`
  query HomeQuery {
    sanityLayoutHome(slug: { current: { eq: "home" } }) {
      ...HomeParts
    }
  }
`
