import React, { useRef, useCallback } from "react"

import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3"
import querystring from "querystring"

const ContactForm = ({ contact }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const formInstance = useRef()
  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      if (!executeRecaptcha) {
        return
      }
      const action = "contact_us"
      const result = executeRecaptcha(action)
      if (result) {
        const formData = new FormData(formInstance.current)
        formData.append("to", "mtolosa@redvalley.io")
        formData.append("redirect", "https://salesprout.com/")
        formData.append("site", "https://salesprout.com/")
        formData.append("token", result)
        formData.append("key", process.env.GATSBY_GOGGLE_RECAPTCHA_SECRET_KEY)
        formData.append("action", action)
        let object = {}
        formData.forEach((value, key) => {
          object = { ...object, [key]: value }
        })
        const query = querystring.stringify(object)
        fetch(
          "https://l6kg4l50gi.execute-api.us-east-1.amazonaws.com/send-forms",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: query,
          }
        )
      }
    },
    [executeRecaptcha]
  )

  return (
    <form
      onSubmit={handleSubmit}
      ref={formInstance}
      className="home-contact--form"
    >
      <input
        className="home-contact--form__input"
        type="text"
        name="name"
        placeholder="Full Name"
        required
      />
      <input
        className="home-contact--form__input"
        type="text"
        name="company"
        placeholder="Company"
        required
      />
      <input
        className="home-contact--form__input"
        type="text"
        name="telephone"
        placeholder="Telephone"
        required
      />
      <input
        className="home-contact--form__input"
        type="email"
        name="email"
        placeholder="E-mail"
        required
      />
      <button className="btn btn-outline-main text-uppercase margin-top-15">
        {contact}
      </button>
      <GoogleReCaptcha />
    </form>
  )
}

export default ContactForm
