import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-plugin-sanity-image'

import '../../graphql/header-home'

const HeaderHome = () => {
  const {
    sanityHeaderHome: {
      logo, ctaEnable, ctaLink, ctaText
    }
  } = useStaticQuery(graphql`
    query HeaderHomeQuery {
      sanityHeaderHome {
        ...HeaderHomeParts
      }
    }
  `)

  return (
    <header className="header-home">
      <Container>
        <Row className="align-items-center justify-content-between animate__animated animate__bounceInDown">
          <Col xs={12} sm={12} md={3} lg={3}>
            <figure className="header-logo">
              <Image
                {...logo}
                className="d-block img-fluid"
                alt="Salesprout"
              />
            </figure>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3}>
            {ctaEnable && (
              <AnchorLink href={ctaLink} className="btn btn-block btn-main text-uppercase d-none d-md-block">
                {ctaText}
              </AnchorLink>
            )}
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default HeaderHome
