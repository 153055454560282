import React from 'react'
import { Link } from 'gatsby'
import Image from 'gatsby-plugin-sanity-image'
import Block from '../../components/block'

const Banner = ({ title, excerpt, ctaEnable, ctaLink, ctaText, logo, logoEnable }) => (
  <article className="home-banner-cta--excerpt text-center">
    <div className="home-banner-cta--excerpt__title">{title}</div>
    <Block data={excerpt} tag="p" />
    {ctaEnable && (
      <Link to={ctaLink} className="btn btn-outline-main text-uppercase margin-top-15">
        {ctaText}
      </Link>
    )}
    {logoEnable && (
      <Image
        {...logo}
        className="d-block m-auto img-fluid"
        alt="Salesprout"
      />
    )}
  </article>
)

export default Banner
