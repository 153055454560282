import React from 'react'
import { Row, Col } from 'reactstrap'
import Block from '../../components/block'

const Testimonial = ({ author, image, description }) => (
  <div className="home-testimonials--item">
    <Row className="align-items-start justify-content-center">
      <Col xs={12} sm={12} md={6} lg={10} className="home-testimonials--item__excerpt">
        <p>
          <Block data={description} />
        </p>
        <p className="author">{author}</p>
      </Col>
    </Row>
  </div>
)

export default Testimonial
