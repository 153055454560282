import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-plugin-sanity-image'
import Block from '../../components/block'

import '../../graphql/footer'

const Footer = () => {
  const {
    sanityFooterHome: {
      widgetLeft, widgetRight
    }
  } = useStaticQuery(graphql`
    query FooterHomeQuery {
      sanityFooterHome {
        ...FooterHomeParts
      }
    }
  `)

  return (
    <footer className="footer-home main--bg-green">
      <Container>
        <Row className="align-items-end justify-content-between flex-column-reverse flex-md-row">
          {widgetLeft?.enable && (
            <Col xs={12} sm={12} md={3} lg={3}>
              <nav className="footer-home--menu">
                <ul>
                  {widgetLeft?.menu?.map(i => (
                    <li className="footer-home--menu__item" key={i._key}>
                      <Link to={i.link}>{i.title}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
              <div className="footer-home--copy">{widgetLeft?.copy}</div>
            </Col>
          )}
          {widgetRight?.enable && (
            <Col xs={12} sm={12} md={4} lg={4}>
              <figure className="footer-home--logo">
                <Image
                  {...widgetRight?.logo}
                  className=""
                  alt="Salesprout"
                />
              </figure>
              <div className="footer-home--locations">
                <Block data={widgetRight?.location} tag="p" />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
