import React, { useState } from 'react'
import { Container } from 'reactstrap'
import { Link, useStaticQuery, graphql } from 'gatsby'
import classesName from 'classnames';

import '../../graphql/menu'

const Menu = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const collapseMenu = () => setIsOpenMenu(!isOpenMenu);

  const {
    sanityMainMenu: { items }
  } = useStaticQuery(graphql`
    query MainMenuQuery {
      sanityMainMenu {
        ...MainMenuParts
      }
    }
  `)

  return (
    <section className="main-menu">
      <section className="main-menu--burger animate__animated animate__fadeInRight">
        <input className="main-menu--burger__action" type="checkbox" onClick={collapseMenu} />
        <span className="main-menu--burger__line"></span>
        <span className="main-menu--burger__line"></span>
        <span className="main-menu--burger__line"></span>
      </section>
      <nav
        className={classesName({
          'main-menu--section main--bg-green': true,
          'main-menu--section__open': isOpenMenu,
        })}
      >
        <Container>
          <ul className="main-menu--list">
            {items?.map(i => (
              <li className="main-menu--list__item" key={i._key}>
                <Link onClick={collapseMenu} to={i.link}>{i.title}</Link>
              </li>
            ))}
          </ul>
        </Container>
      </nav>
    </section>
  )
}

export default Menu
