import React from 'react'
import { Row, Col } from 'reactstrap'
import Image from 'gatsby-plugin-sanity-image'
import Block from '../../components/block'

const Service = ({ title, icon, description }) => (
  <div className="animate__animated animate__fadeIn home-services--item">
    <Row className="align-items-start">
      <Col xs={12} sm={12} md={3} lg={3} className="home-services--item__ico">
        <Image
          {...icon}
          className="img-fluid"
          alt="Salesprout"
        />
      </Col>
      <Col xs={12} sm={12} md={9} lg={9} className="home-services--item__excerpt">
        <p className="subtitle">
          <Image
            {...icon}
            className="d-inline d-md-none img-fluid"
            alt="Salesprout"
          />
          {title}
        </p>
        <Block data={description} tag="p" />
      </Col>
    </Row>
  </div>
)

export default Service
